import React, {useContext} from "react";
// import { Button, Menu, MenuItem, Tooltip } from "@mui/core";
import LanguageIcon from "@mui/icons-material/Translate";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import * as style from "./LanguageSwitcher.module.scss";
import {Button, Menu, MenuItem, Tooltip} from "@mui/material";
import {useI18next} from "gatsby-plugin-react-i18next";

const LANGUAGES_LABEL = {
    "pl": "Polski",
    "en": "English",
    "de": "Deutsch"
}

export const LanguageSwitcher = () => {
    const {languages, changeLanguage, language} = useI18next();
    const [languageMenu, setLanguageMenu] = React.useState(null);
    const selectedLanguageLabel = LANGUAGES_LABEL[language] || 'Polski';

    const handleLanguageIconClick = (event) => {
        setLanguageMenu(event.currentTarget);
    };

    const handleLanguageMenuClose = (event) => {
        const language = event.target.lang;
        if (language) {
            changeLanguage(language);
            ;
        }
        setLanguageMenu(null);
    };

    return (
        <div className={style.LanguageSwitcher} data-testid={"language-switcher"}>
            <Tooltip
                title={"Zmień język"}
                enterDelay={300}
            >
                <Button
                    color="inherit"
                    aria-owns={languageMenu ? "language-menu" : undefined}
                    aria-haspopup="true"
                    aria-label={"changeLanguage"}
                    onClick={handleLanguageIconClick}
                    data-ga-event-category="header"
                    data-testid={"language-menu-button"}
                    data-ga-event-action="language"
                >
                    <LanguageIcon/>
                    <span className={style.language} data-testid={"selected-language"}>
            {selectedLanguageLabel}
          </span>
                    <ExpandMoreIcon fontSize="small"/>
                </Button>
            </Tooltip>
            <Menu
                data-testid="language-menu"
                id="language-menu"
                anchorEl={languageMenu}
                open={Boolean(languageMenu)}
                onClose={handleLanguageMenuClose}
            >
                {languages.map((lang) => (
                    <MenuItem
                        component="a"
                        data-no-link="true"
                        key={lang}
                        data-testid={"language-item"}
                        selected={language === lang}
                        onClick={handleLanguageMenuClose}
                        lang={lang}
                        hrefLang={lang}
                    >
                        {LANGUAGES_LABEL[lang] || 'Polski'}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
};
