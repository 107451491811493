import Helmet from "react-helmet";
import * as React from "react";
import {useI18next} from "gatsby-plugin-react-i18next";


const SEO = () => {
    const {language} = useI18next();

    const pl = (<Helmet>
        <meta charSet="utf-8"/>
        <title>LOGGY.pl - darmowe planowanie załadunku</title>
        <html lang="pl"/>
        <meta
            name="description"
            content="Zaplanuj swój ładunek za darmo!"
        />

        <meta property="og:title" content="Loggy - darmowe planowanie załadunku"/>
        <meta property="og:description"
              content="Zaplanuj przestrzeń ładunkową w szybki i intuicyjny sposób - za darmo i bez rejestracji!"/>
        <meta property="og:image" content="https://loggy.pl/fb-loggy-pl.png"/>
        <link rel="alternate" href="https://loggy.pl/en" hrefLang={"en"}/>
        <link rel="alternate" href="https://loggy.pl/de" hrefLang={"de"}/>
        <link rel="alternate" href="https://loggy.pl" hrefLang={"pl"}/>
        <link rel="alternate" href="https://loggy.pl" hrefLang={"x-default"}/>

    </Helmet>)

    const en = (<Helmet>
        <meta charSet="utf-8"/>
        <title>LOGGY.pl - free cargo space planning</title>
        <html lang={"en"}/>
        <meta
            name="description"
            content="Plan your cargo space for free!"
        />
        <meta property="og:title" content="Loggy - free loading space planner"/>
        <meta property="og:description"
              content="Plan your cargo space in a fast and intuitive way - for free and without sign up!"/>
        <meta property="og:image" content="https://loggy.pl/fb-loggy-en.png"/>
        <link rel="alternate" href="https://loggy.pl/en" hrefLang={"en"}/>
        <link rel="alternate" href="https://loggy.pl/de" hrefLang={"de"}/>
        <link rel="alternate" href="https://loggy.pl" hrefLang={"pl"}/>
        <link rel="alternate" href="https://loggy.pl" hrefLang={"x-default"}/>
    </Helmet>)

    const de = (<Helmet>
        <meta charSet="utf-8"/>
        <title>LOGGY.pl - Planen Sie Ihre Ladung kostenfrei!</title>
        <html lang={"en"}/>
        <meta
            name="description"
            content="Planen Sie Ihre Ladung kostenfrei!"
        />
        <meta property="og:title" content="Loggy - Planen Sie Ihre Ladung kostenfrei!"/>
        <meta property="og:description"
              content="Planen Sie Ihren Laderaum schnell und intuitiv – kostenlos und ohne Anmeldung!"/>
        <meta property="og:image" content="https://loggy.pl/fb-loggy-de.jpg"/>
        <link rel="alternate" href="https://loggy.pl/en" hrefLang={"en"}/>
        <link rel="alternate" href="https://loggy.pl/de" hrefLang={"de"}/>
        <link rel="alternate" href="https://loggy.pl" hrefLang={"pl"}/>
        <link rel="alternate" href="https://loggy.pl" hrefLang={"x-default"}/>
    </Helmet>)

    if (language === 'pl') {
        return pl
    } else if (language === 'de') {
        return de
    }
    return en
}


export default SEO;
