import * as React from 'react';
import {styled} from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';;
import {useI18next} from "gatsby-plugin-react-i18next";
import * as styles from './TermsAndConditions.module.scss';

const BootstrapDialog = styled(Dialog)(({theme}) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const BootstrapDialogTitle = (props) => {
    const {children, onClose, ...other} = props;

    return (
        <DialogTitle sx={{m: 0, p: 2}} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon/>
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

interface Props {
    open: boolean;
    onClose: () => void;
}

export default function TermsAndConditions({open, onClose}: Props) {
    const {language} = useI18next();
    return (
        <BootstrapDialog
            onClose={onClose}
            aria-labelledby="customized-dialog-title"
            open={open}
        >
            {language === 'pl' && <>
                <BootstrapDialogTitle id="customized-dialog-title" onClose={onClose}>
                    Ogólne Warunki Użytkowania Aplikacji
                </BootstrapDialogTitle>
                <DialogContent dividers className={styles.Content}>
                    <h3>I. DEFINICJE</h3>
                    <p>1. <strong>APLIKACJA</strong> - użytkowa aplikacja internetowa będąca własnością Właściciela
                        udostępniana on-line
                        za pośrednictwem przeglądarki internetowej</p>

                    <p>2. <strong>EWSLETTER</strong> - Usługa Elektroniczna pozwalająca Użytkownikowi na złożenie
                        zamówienia
                        dotyczącego otrzymywania na podany adres e-mail bezpłatnych informacji pochodzących od
                        Właściciela, dotyczących aplikacji;</p>

                    <p>3. <strong>UŻYTKOWNIK</strong> - osoba fizyczna, osoba prawna albo jednostka organizacyjna
                        nieposiadająca
                        osobowości prawnej, której ustawa przyznaje zdolność prawną, korzystająca z usług
                        elektronicznych dostępnych w ramach aplikacji;</p>

                    <p>5. <strong>WARUNKI</strong> - zbiór wszystkich postanowień m.in. niniejszych Ogólnych
                        warunków, zasad polityki
                        prywatności, plików cookies, regulaminu korzystania ze sklepu internetowego oraz wszelkich
                        innych warunków, znajdujących się w aplikacji, które dotyczą określonych funkcji,
                        właściwości
                        lub promocji, jak również obsługi klienta;</p>

                    <p>6. <strong>WŁAŚCICIEL</strong> - Podmiot udostępniający niniejszą aplikację, mianowicie:
                        LOGGY SPÓŁKĘ Z
                        OGRANICZONA ODPOWIEDZIALNOSCIĄ - z siedzibą w Warszawie, ul. Świeradowska 47, 02-662
                        Warszawa,
                        wpisaną do rejestru przedsiębiorców, prowadzonego przez Sąd Rejonowy dla m.st. Warszawy,
                        Wydział
                        XIII Gospodarczy Krajowego Rejestru Sądowego pod nr KRS 0000802199, o kapitale zakładowym w
                        wysokości 5.000,00 złotych NIP: 521-387-55-58, REGON 384286533</p>

                    <h3>II. ZAKRES WARUNKÓW</h3>
                    <p>1. Właściciel zapewnia dostęp do zawartości aplikacji, zgodnie z poniższymi Ogólnymi
                        warunkami.</p>

                    <p>2. Właściciel ma prawo zamieszczania treści reklamowych, które stanowią integralną część
                        serwisu
                        i prezentowanych w nim materiałów.</p>

                    <p>3. Użytkownicy mogą korzystać z dostępu i usług oferowanych w aplikacji, pod warunkiem
                        uprzedniego wyrażenia zgody na Ogólne warunki.</p>

                    <h3>III. ZASADY KORZYSTANIA Z APLIKACJI</h3>
                    <p>1. Aktualne wymogi techniczne niezbędne do korzystania z Aplikacji przez użytkownika to:<br/>
                        * procesor 1800 MHz<br/>
                        * pamięć RAM: 4 GB<br/>
                        * dostęp do Internetu<br/>
                        * aktualna przeglądarka Chrome / Safari / Firefox<br/>
                    </p>

                    <p>2. Zakazane jest używanie i kopiowanie oprogramowania, procesów i technologii, stanowiących
                        część aplikacji.</p>

                    <p>3. Zabronione jest korzystanie z aplikacji:<br/>
                        - w sposób prowadzący do naruszenia obowiązujących przepisów prawa;<br/>
                        - w jakikolwiek sposób niezgodny z prawem lub nieuczciwy, albo w sposób, zmierzający do
                        osiągnięcia niezgodnego z prawem lub nieuczciwego celu;<br/>
                        - do wysyłania, świadomego otrzymywania, wgrywania lub używania treści niezgodnych z
                        Ogólnymi
                        warunkami;
                    </p>

                    <h3>V. NEWSLETTER</h3>
                    <p>1. Istnieje możliwość subskrybowania newslettera, poprzez podanie adresu e-mail oraz imienia
                        i
                        wyrażenie zgody na przetwarzanie danych osobowych Użytkownika, w celu otrzymywania od
                        Właściciela informacji handlowych oraz marketingowych, drogą mailową lub za pomocą
                        wiadomości
                        SMS.</p>

                    <p>2. Poprzez newsletter, wysłany drogą e-mailową lub Właściciel informuje Użytkownika o
                        najnowszych ofertach np. nowościach, promocjach czy wyprzedażach.</p>

                    <p>3. Użytkownik może w każdym czasie zrezygnować z subskrypcji newslettera, klikając w link
                        zawarty w otrzymanym newsletterze lub przesyłając wiadomość e-mail na adres:
                        biuro@loggy.pl.</p>

                    <h3>VI. OCHRONA DANYCH UŻYTKOWNIKA</h3>

                    <p>Właściciel szanuje w pełni prywatność Użytkowników. Szczegółowe informacje na temat sposobu
                        gromadzenia i przetwarzania danych osobowych Użytkownika lub innych informacji, jak również
                        sytuacji, w których Właściciel może je ujawniać, znajdują się w zakładce Polityka
                        Prywatności.</p>

                    <h3>VII. OGRANICZENIE ODPOWIEDZIALNOŚCI</h3>
                    <p>1. Aplikacja zawiera informacje o charakterze ogólnym. Nie ma na celu pośredniczyć w
                        świadczeniu
                        jakichkolwiek usług doradztwa profesjonalnego. Przed podjęciem czynności mających wpływ na
                        sytuację finansową lub działalność gospodarczą Użytkownika należy skontaktować się z
                        profesjonalnym doradcą.</p>

                    <p>2. Aplikacja nie zapewnia żadnych gwarancji dotyczących jej treści, w szczególności gwarancji
                        bezpieczeństwa, bezbłędności, braku wirusów czy złośliwych kodów, gwarancji dotyczących
                        poprawnego działania czy jakości.</p>

                    <p>3. Aplikacja nie zapewnia żadnej rękojmi, wyraźnej lub dorozumianej, w tym gwarancji
                        przydatności handlowej lub przydatności do określonego celu, nienaruszenia praw autorskich,
                        dostosowania, bezpieczeństwa i rzetelności informacji.</p>

                    <p>4. Użytkownik korzysta z aplikacji na własne ryzyko i przyjmuje na siebie pełną
                        odpowiedzialność
                        za szkody związane lub wynikające z jej wykorzystania, zarówno bezpośrednie jak i pośrednie,
                        uboczne, następcze, moralne, lub inne szkody z tytułu odpowiedzialności umownej, deliktowej,
                        z
                        tytułu zaniedbań, w tym m.in. za utratę danych lub usług.</p>

                    <p>6. Właściciel nie ponosi odpowiedzialności, jeśli aplikacja jest z jakichkolwiek przyczyn
                        tymczasowo lub długookresowo niedostępna.</p>

                    <p>7. Właściciel nie ponosi odpowiedzialności za informacje przekazywane w aplikacji.</p>

                    <p>8. Pomimo podejmowania przez Właściciela największych starań, w kwestii zapewnienia
                        dokładności
                        i aktualności aplikacji, mogą pojawić się niezamierzone przez Właściciela błędy, które
                        Użytkownik, po ich wykryciu, proszony jest zgłaszać Właścicielowi.</p>

                    <p>9. Wszystkie wskazane powyżej wyłączenia i ograniczenia odpowiedzialności obowiązują w
                        najszerszym dopuszczalnym prawnie zakresie, obejmując każdy typ istniejącej
                        odpowiedzialności
                        m.in. odpowiedzialności kontraktowej, deliktowej i każdej innej przewidzianej w polskim lub
                        zagranicznym porządku prawnym.</p>

                    <h3>VIII. ZMIANA WARUNKÓW APLIKACJI</h3>

                    <p>1. Właściciel aplikacji zastrzega sobie prawo do dokonywania modyfikacji niniejszych Ogólnych
                        warunków, w dowolnym momencie ich obowiązywania, zamieszczając ich zaktualizowaną wersję w
                        aplikacji, które zaczynają obowiązywać Użytkowników od momentu ich publikacji, chyba że
                        inaczej
                        wskazano w zmodyfikowanych Ogólnych warunkach.</p>

                    <p>2. Użytkownik ma obowiązek zapoznania się z modyfikacjami Ogólnych warunków, o czym
                        Właściciel
                        poinformuje go, wysyłając do Niego wiadomość lub komunikat o zmianach Ogólnych warunków do
                        zaakceptowania.</p>

                    <p>3. Dalsze korzystanie z aplikacji jest równoznaczne z akceptacją zmodyfikowanych Warunków
                        aplikacji.</p>

                </DialogContent>
            </>}
            {language === 'de' && <>
                <BootstrapDialogTitle id="customized-dialog-title" onClose={onClose}>
                    Geschäftsbedingungen
                </BootstrapDialogTitle>
                <DialogContent dividers className={styles.Content}>
                    <h3>I. BEGRIFFSBESTIMMUNGEN</h3>
                    <p>1. <strong>ANWENDUNG</strong> - eine Webanwendung des Eigentümers, die online über einen
                        Webbrowser verfügbar ist</p>
                    <p>2. <strong>NEWSLETTER</strong> - ein elektronischer Dienst, der es dem Nutzer ermöglicht, eine
                        Bestellung
                        aufzugeben, um kostenlose Informationen des Eigentümers über die Anwendung an die
                        angegebene E-Mail-Adresse zu erhalten;
                    </p>
                    <p>3. <strong>BENUTZER</strong> - eine Person, eine juristische Person oder eine organisatorische
                        Einheit ohne
                        Rechtspersönlichkeit, der das Gesetz die Rechtsfähigkeit zuerkennt, die die im Rahmen der
                        Anwendung verfügbaren elektronischen Dienste nutzt;
                    </p>
                    <p>4. <strong>BEDINGUNGEN</strong> - eine Reihe von Bestimmungen, einschließlich dieser Allgemeinen
                        Geschäftsbedingungen, der Datenschutzrichtlinie, Cookies und anderer Bedingungen in der
                        Anwendung, die sich auf bestimmte Funktionen, Eigenschaften oder Aktionen sowie auf
                        den Kundendienst beziehen;
                    </p>
                    <p>6. <strong>EIGENTÜMER</strong> - Das Unternehmen, das diese Anwendung bereitstellt: Loggy sp. z
                        o. o.,
                        ul. Świeradowska 47, 02-662 Warszawa, KRS 0000802199, NIP: 521-387-55-58, REGON
                        384286533
                    </p>
                    <h3>II. UMFANG DER TERMINE</h3>
                    <p>1. Der Eigentümer gewährt den Zugang zum Inhalt der Anwendung in Übereinstimmung
                        mit den folgenden Allgemeinen Geschäftsbedingungen.
                    </p>
                    <p>
                        2. Der Eigentümer hat das Recht, Werbeinhalte zu veröffentlichen, die einen integralen
                        Bestandteil der Website und der darin dargestellten Materialien bilden.
                    </p>
                    <p>3. Die Nutzer können die in der Anwendung angebotenen Zugänge und Dienste nutzen,
                        sofern sie den Allgemeinen Geschäftsbedingungen zugestimmt haben.
                    </p>
                    <h3>III. REGELN FÜR DIE NUTZUNG DER ANWENDUNG</h3>
                    <p>1. Die aktuellen technischen Voraussetzungen für die Nutzung der Anwendung durch den
                        Nutzer sind: <br/>
                        * Prozessor mit 1800 MHz <br/>
                        * RAM: 4 GB <br/>
                        * Internetzugang <br/>
                        * aktueller Browser Chrome / Safari / Firefox<br/>
                    </p>
                    <p>2. Es ist verboten, die Software, Prozesse und Technologien, die Teil der Anwendung sind,
                        zu verwenden oder zu kopieren.</p>

                    <p>3. Es ist untersagt, die Anwendung zu nutzen: <br/>
                        - in einer Weise, die gegen geltendes Recht verstößt; <br/>
                        - in einer Art und Weise, die ungesetzlich oder unehrlich ist, oder in einer Art und Weise,
                        die darauf abzielt, einen illegalen oder unlauteren Zweck zu erreichen;
                        <br/>
                        - Inhalte zu versenden, hochzuladen oder zu verwenden, die nicht mit den Allgemeinen
                        Geschäftsbedingungen übereinstimmen;
                    </p>
                    <h3>V. NEWSLETTER</h3>
                    <p>1. Es besteht die Möglichkeit, sich für den Newsletter anzumelden, indem man seine E-
                        Mail-Adresse und seinen Vornamen angibt und der Verarbeitung der persönlichen Daten
                        des Nutzers zustimmt, um kommerzielle und Marketing-Informationen vom Eigentümer
                        per E-Mail oder per SMS zu erhalten.
                    </p>
                    <p>2. Durch den Newsletter, der per E-Mail verschickt wird, informiert der Inhaber den
                        Benutzer über die neuesten Angebote, wie z.B. neue Produkte, Werbeaktionen oder
                        Verkäufe.
                    </p>
                    <p>3. Der Nutzer kann den Newsletter jederzeit abbestellen, indem er auf den in dem
                        erhaltenen Newsletter enthaltenen Link klickt oder eine E-Mail an die folgende Adresse
                        sendet: biuro@loggy.pl.
                    </p>
                    <h3>VI. SCHUTZ DER BENUTZERDATEN</h3>
                    <p>
                        Der Eigentümer respektiert die Privatsphäre der Nutzer in vollem Umfang. Detaillierte
                        Informationen über die Art und Weise der Erfassung und Verarbeitung der persönlichen
                        Daten oder anderer Informationen des Nutzers sowie über die Situationen, in denen der
                        Eigentümer diese offenlegen kann, sind in der Registerkarte Datenschutzpolitik zu finden.
                    </p>
                    <h3>
                        VII. HAFTUNGSBESCHRÄNKUNG
                    </h3>
                    <p>1. Die Anwendung enthält Informationen allgemeiner Art. Sie ist nicht als Vermittler für
                        professionelle Beratungsdienste gedacht. Bevor der Nutzer Maßnahmen ergreift, die sich
                        auf seine finanzielle oder geschäftliche Situation auswirken, muss er sich an einen
                        professionellen Berater wenden.
                    </p>
                    <p>2. Die Anwendung bietet keine Garantien in Bezug auf ihren Inhalt, insbesondere keine
                        Garantien in Bezug auf Sicherheit, Fehlerfreiheit, das Nichtvorhandensein von Viren oder
                        bösartigen Codes, Garantien in Bezug auf den ordnungsgemäßen Betrieb oder die Qualität.
                    </p>
                    <p>3. Die Anwendung bietet keine ausdrückliche oder stillschweigende Garantie,
                        einschließlich der Garantie der Marktgängigkeit oder der Eignung für einen bestimmten
                        Zweck, der Nicht-Verletzung von Urheberrechten, der Anpassung, der Sicherheit und der
                        Genauigkeit der Informationen.
                    </p>
                    <p>4. Der Nutzer nutzt die Anwendung auf eigenes Risiko und übernimmt die volle
                        Verantwortung für Schäden, die mit der Nutzung der Anwendung zusammenhängen oder
                        daraus resultieren, sowohl für direkte als auch für indirekte, zufällige, Folgeschäden,
                        moralische oder andere Schäden aufgrund von Vertrag, unerlaubter Handlung oder
                        Fahrlässigkeit, einschließlich des Verlusts von Daten oder Dienstleistungen.
                    </p>
                    <p>5. Der Eigentümer ist nicht verantwortlich, wenn die Anwendung aus irgendeinem Grund
                        vorübergehend oder langfristig nicht verfügbar ist.
                    </p>
                    <p>6. Der Eigentümer ist nicht verantwortlich für die in der Anwendung bereitgestellten
                        Informationen.</p>
                    <p>7. Trotz der Bemühungen des Eigentümers, die Richtigkeit und Aktualität der Anwendung
                        zu gewährleisten, können Fehler auftreten, die vom Eigentümer nicht beabsichtigt sind
                        und die der Nutzer bei Entdeckung dem Eigentümer melden muss.</p>

                    <p>8. Alle oben genannten Haftungsausschlüsse und -beschränkungen gelten im vollen
                        gesetzlich zulässigen Umfang, einschließlich aller Arten von bestehender Haftung,
                        einschließlich vertraglicher, deliktischer und sonstiger Haftung, die in der polnischen oder
                        ausländischen Rechtsordnung vorgesehen ist.
                    </p>
                    <h3>VIII. ÄNDERUNG DER ANWENDUNGSBEDINGUNGEN
                    </h3>
                    <p>1. Der Inhaber der Anwendung behält sich das Recht vor, diese Allgemeinen
                        Geschäftsbedingungen jederzeit zu ändern, indem er ihre aktualisierte Version in der
                        Anwendung veröffentlicht, die ab der Veröffentlichung für die Nutzer gilt, sofern in den
                        geänderten Allgemeinen Geschäftsbedingungen nichts anderes angegeben ist.
                    </p>
                    <p>2. Der Nutzer ist verpflichtet, die Änderungen der Allgemeinen Geschäftsbedingungen zu
                        lesen, über die ihn der Betreiber durch eine Nachricht oder eine Mitteilung über die
                        Änderungen der Allgemeinen Geschäftsbedingungen informiert.
                    </p>
                    <p>3. Indem Sie die Anwendung weiterhin verwenden, akzeptieren Sie die geänderten
                        Anwendungsbedingungen.</p>
                </DialogContent>
            </>
            }
            {language !== 'pl' && language !== 'de' && <>
                <BootstrapDialogTitle id="customized-dialog-title" onClose={onClose}>
                    Terms and conditions
                </BootstrapDialogTitle>
                <DialogContent dividers className={styles.Content}>
                    <h3>I. DEFINITIONS</h3>
                    <p>1. <strong>APPLICATION</strong> - a web application owned by the Owner, available on-line via a
                        web browser</p>
                    <p>2. <strong>NEWSLETTER</strong> - an Electronic Service that allows the User to place an order to
                        receive free information from the Owner regarding the
                        application to the provided e-mail address;
                    </p>
                    <p>3. <strong>USER</strong> - a person, legal
                        person or an organizational unit without legal personality, which the law grants legal capacity,
                        using electronic services available as part of the application;
                    </p>
                    <p>5. <strong>CONDITIONS</strong> - a set of all provisions,
                        incl. these General Terms and Conditions, the Privacy
                        Policy, Cookies any other terms and conditions in the
                        application that relate to specific functions, properties or promotions, as well as customer
                        service;
                    </p>
                    <p>6. <strong>OWNER</strong> - The entity providing this
                        application: Loggy sp. z o. o., ul.
                        Świeradowska 47, 02-662 Warszawa, KRS 0000802199, NIP: 521-387-55-58, REGON 384286533
                    </p>
                    <h3>II. SCOPE OF TERMS</h3>
                    <p>1. The owner provides access to the content of the application in
                        accordance with the following General Terms and Conditions.
                    </p>
                    <p>
                        2. The owner has the right to post advertising content that constitutes an integral part of the
                        website and the materials presented therein.
                    </p>
                    <p>3. Users may use the access and services offered in the
                        application, subject to prior consent to the General Terms and Conditions.
                    </p>
                    <h3>III. RULES OF USING THE APPLICATION</h3>
                    <p>1. The current technical requirements necessary to use the Application by the user are: <br/>
                        * 1800 MHz processor <br/>
                        * RAM: 4 GB <br/>
                        * Internet access <br/>* the current Chrome / Safari / Firefox browser<br/>
                    </p>
                    <p>2. It is forbidden to use or copy the software, processes and technologies that are part of the
                        application.</p>

                    <p>3. It is forbidden to use the application: <br/>
                        - in a way that violates applicable law; <br/>
                        - in any way that is unlawful or dishonest, or in any way aimed at achieving an illegal or
                        unfair
                        purpose; <br/>
                        - to send, upload or use content that does not comply with the General Terms and Conditions;
                    </p>
                    <h3>V. NEWSLETTER</h3>
                    <p>1. It is possible to subscribe to the newsletter by providing an e-mail address and first name
                        and
                        consenting to theprocessing of the User's personal data in order to receive commercial and
                        marketing
                        information from the Owner, by e-mail or via SMS.
                    </p>
                    <p>2. Through the newsletter, sent by e-mail, or the Owner informs the User about the latest offers,
                        such as new products, promotions or sales.
                    </p>
                    <p>3. The User may unsubscribe from the newsletter at any time by clicking on the link included in
                        the
                        newsletter received or by
                        sending an e-mail to the following address: biuro@loggy.pl.
                    </p>
                    <h3>VI. PROTECTION OF USER DATA</h3>
                    <p>
                        The Owner fully respects the privacy of Users. Detailed information on the method of collecting
                        and
                        processing User's personal data or other information, as well as situations in
                        which the Owner may disclose them, can be found in the Privacy Policy tab.
                    </p>
                    <h3>
                        VII. LIMITATION OF LIABILITY
                    </h3>
                    <p>1. The application contains information of a general nature. It is not intended to be an
                        intermediary
                        in the provision of any professional counseling services. Before taking any
                        action that has an impact on the User's financial
                        situation or business, it is necessary to contact a professional advisor.
                    </p>
                    <p>2. The application does not provide any guarantees regarding its content, in particular
                        guarantees of
                        security, error-free, no
                        viruses or malicious codes, guarantees of proper operation or quality.
                    </p>
                    <p>3. The application provides no warranty, express or implied, including the warranty of
                        merchantability or fitness for a
                        particular purpose, non-infringement of copyrights, adaptation, security and accuracy of
                        information.
                    </p>
                    <p>4. The user uses the application at his own risk and assumes full
                        responsibility for damages related to or resulting from its use, both direct and indirect,
                        incidental, consequential, moral, or other damages due to contractual, tort, negligence,
                        including for loss of data or services.
                    </p>
                    <p>6. The owner is not responsible if the application is temporarily or long-term unavailable for
                        any
                        reason.
                    </p>
                    <p>7. The owner is not responsible for the information provided in the application.</p>
                    <p>8. Despite the Owner's best efforts to ensure the accuracy and timeliness of the application,
                        errors
                        may appear unintended by
                        the Owner, which the User, upon detection, is asked to report to the Owner.
                    </p>
                    <p>
                        9. All the above-mentioned exclusions and limitations of liability are valid to the fullest
                        extent
                        permitted by law, including all types of existing liability, including contractual, tort and any
                        other
                        liability provided for in
                        Polish or foreign legal order.
                    </p>
                    <h3>VIII. CHANGE OF APPLICATION TERMS
                    </h3>
                    <p>1. The Application Owner reserves the right to modify these General
                        Terms and Conditions at any time by posting their updated version in the Application, which will
                        apply to Users upon publication, unless otherwise stated in the modified General Terms and
                        Conditions.
                    </p>
                    <p>2. The User is obliged to read the modifications to the General
                        Conditions, about which the Owner will inform him by sending him a message or a notification of
                        changes to the General Conditions to be accepted.
                    </p>
                    <p>3. By continuing to use the application, you accept the modified Application Terms.</p>
                </DialogContent>
            </>
            }
        </BootstrapDialog>
    );
}

