import * as React from "react"

if (typeof window !== "undefined") {
    // eslint-disable-next-line global-require
    require("smooth-scroll")('a[href*="#"]', {offset: 70})
}
import cx from "classnames";
import Close from "@mui/icons-material/Close";
import Menu from "@mui/icons-material/Menu";
import {Box, Button, Container, Grid} from "@mui/material";
import {SignUpButton} from "../SignUpButton/SignUpButton";
import * as styles from "./Header.module.scss";
import {useState} from "react";
import {LanguageSwitcher} from "../LanguageSwitcher/LanguageSwitcher";
import {useTranslation} from "react-i18next";

interface HeaderProps {
    appUrl?: string;
    isHeaderFixed: boolean;
}

const PageHeader = (props: HeaderProps) => {
    const {t} = useTranslation();
    const [mobileMenuVisible, setMobileMenuVisiblity] = useState(false);
    const {isHeaderFixed} = props;
    const toggleMobileMenu = () => {
        setMobileMenuVisiblity(!mobileMenuVisible)
    };
    const loadingRedirect = () => {
        window.location.replace("https://platform.loggy.pl");
    }

    return (
        <Container maxWidth={false} className={cx(styles.Header, {[styles.FixedHeader]: isHeaderFixed})}>
            <Grid container className={cx(styles.HeaderItem)} justifyContent="center" alignItems="center">
                <Grid item xs className={cx(styles.LeftSection)}
                      sx={{justifyContent: {xs: 'space-between', md: 'flex-start'}}}>
                    <a href={'/'} className={styles.Logo}>Loggy</a>
                    <Box sx={{display: {xs: 'none', md: 'flex'}}}>
                        <nav>
                            <ul className={cx(styles.Navigation, styles.MainMenu)}>
                                <li><a href="/#product">{t("menu.product")}</a></li>
                                <li><a href="/#features">{t("menu.features")}</a></li>
                                <li><a href="/#newsletter">{t("menu.newsletter")}</a></li>
                                <li>
                                    <Button variant="outlined" color="primary" size={"small"} onClick={loadingRedirect}>
                                        {t("menu.meetPlatform")}
                                    </Button>
                                </li>
                            </ul>
                        </nav>
                    </Box>
                    <Box sx={{display: {xs: 'flex', md: 'none'}}}>
                        <Button onClick={toggleMobileMenu} className={'d-block d-lg-none'}><Menu
                            className={cx(styles.MenuButton)}/></Button>
                    </Box>
                </Grid>
                <Grid item xs sx={{display: {xs: 'none', md: 'flex'}}}>

                    <ul className={cx(styles.Navigation)}>
                        <li><LanguageSwitcher/></li>
                        <li>
                            <SignUpButton className={styles.SignUpBtn}/>
                        </li>
                    </ul>
                </Grid>
            </Grid>
            {mobileMenuVisible &&
            <div className={cx(styles.MobileMenuOutline)} onClick={toggleMobileMenu}/>}
            {mobileMenuVisible && <div className={cx(styles.MobileMenu, 'd-lg-none')}>
                <div>
                    <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                        <div className={styles.Logo}>Loggy</div>
                        <Button><Close className={styles.CloseMenu} onClick={toggleMobileMenu}/></Button>
                    </Box>
                    <nav>
                        <ul className={styles.Navigation}>
                            <li><a href="/#product">{t("menu.product")}</a></li>
                            <li><a href="/#features">{t("menu.features")}</a></li>
                            <li><a href="/#newsletter">{t("menu.newsletter")}</a></li>
                            <li><a href="https://platform.loggy.pl" target={"_blank"}>{t("menu.meetPlatform")}</a></li>
                        </ul>
                    </nav>
                </div>
            </div>}
        </Container>
    )
}
;

export default PageHeader;
