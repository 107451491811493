import * as React from "react"
import '../../styles/global.scss';
import PageHeader from "../Header/Header";
import Banner from "../Banner/Banner";
import Helmet from "react-helmet";
import Footer from "../Footer/Footer";
import cx from 'classnames';
import CookieConsent from "react-cookie-consent";
import {useRef, useEffect, useState} from "react";
import * as styles from './Layout.module.scss';
import {createTheme, ThemeProvider} from "@mui/material";
import {useI18next} from "gatsby-plugin-react-i18next";
import SEO from "../SEO/SEO";

const darkTheme = createTheme({
    typography: {
        fontFamily: "'Fira Sans', 'Cantarell', 'Droid Sans', 'Helvetica Neue'",
        fontWeightLight: 100,
        fontWeightRegular: 100,
    },
    palette: {
        mode: 'light',
        background: {
            default: "#000",
            paper: "#2a2d35",
        },
        primary: {
            main: "#7CB342",
            // dark: will be calculated from palette.primary.main,
            contrastText: "#fff",
        },
        secondary: {
            main: "#e51148",
            // dark: will be calculated from palette.secondary.main,
        },
    },
    components: {
        MuiPaper: {
            styleOverrides: {
                root: {
                    backgroundImage: 'none'
                }
            }
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    background: "rgba(68, 70, 79, 0.5)",
                }
            },
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    height: "26px",
                    fontSize: "10px",
                }
            },
        },
        MuiListItem: {
            styleOverrides: {
                root: {
                    paddingLeft: "0",
                    color: '#fff'
                }
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    color: '#fff'
                }
            },
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    color: '#fff'
                }
            },
        },
        MuiDialogContent: {
            styleOverrides: {
                root: {
                    color: '#fff'
                }
            },
        }
    }
});

interface LayoutProps {
    isHomePage: boolean;
}

const Layout: React.FC<LayoutProps> = (props) => {
    const {language} = useI18next();
    const {isHomePage} = props;
    const [isHeaderFixed, setFixedHeader] = useState<boolean>(false);
    const headerEl = useRef<HTMLHeadingElement>(null);

    const onScroll = () => {
        if (headerEl && headerEl.current) {
            const headerOffset = headerEl.current.getBoundingClientRect().top;
            if (headerOffset < 0) {
                setFixedHeader(true);
            } else {
                setFixedHeader(false);
            }
        }
    }
    useEffect(() => {
        window.addEventListener('scroll', onScroll);
        return () => {
            window.removeEventListener('scroll', onScroll);
        };
    })


    const topContentEl = useRef(null);
    return (
        <ThemeProvider theme={darkTheme}>
            <SEO/>
            <div className={styles.Container}>
                <div className={cx(styles.TopContent, {[styles.HasBg]: isHomePage})} ref={topContentEl}>
                    <div>
                        <header ref={headerEl}>
                            <PageHeader isHeaderFixed={isHeaderFixed}/>
                        </header>
                        {isHomePage && <Banner isHeaderFixed={isHeaderFixed}/>}
                    </div>
                </div>
                {isHomePage
                    ? props.children
                    : <div className={cx("container", styles.Content)}>
                        {props.children}
                    </div>}
                <Footer/>
            </div>
            <CookieConsent
                location="bottom"
                buttonText="Rozumiem"
                contentStyle={{margin: 0, paddingRight: '15px'}}
                cookieName="cookiesAccepted"
                style={{
                    background: "#383B44",
                    fontWeight: 300,
                    alignItems: 'center',
                    fontSize: "13px",
                    left: 0,
                    bottom: 0,
                    width: '100%',
                    minHeight: '50px',
                    position: 'fixed',
                    padding: '15px'
                }}
                buttonStyle={{
                    backgroundColor: '#7CB342',
                    color: "#fff",
                    fontSize: "16px",
                    fontWeight: 300,
                    margin: 0,
                }}
                expires={150}
            >
                Ta strona używa plików cookie w celu usprawnienia i ułatwienia dostępu do serwisu oraz prowadzenia
                danych statystycznych. Dalsze korzystanie z tej witryny oznacza akceptację tego stanu rzeczy.
            </CookieConsent>
        </ThemeProvider>
    )
};

export default Layout;

